import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';
import CustomerOverview from '../components/CustomerOverview';
import PortfolioOverview from '../components/PortfolioOverview';
import JobPostingOverview from '../components/JobPostingOverview';
import ServicesOverview from '../components/ServicesOverview';

const Home = ({ location, data }) => {
  const index = data.index;
  const intro = data.intro;
  const portfolio = data.portfolio;
  const site = data.site.siteMetadata;
  const portfolioEntries = data.portfolioEntries.edges;

  const [portfolioHeader, portfolioFirstParagraph, ...portfolioRest] =
    `${portfolio.html}`.split('\n');

  const introImageClasses = `intro-image ${
    intro.frontmatter.image_absolute && 'intro-image-absolute'
  } ${intro.frontmatter.image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} url={location.href} />

      {/* <div className="index mt-10 mb-3">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: index.html }} />
            </div>
          </div>
        </div>
      </div> */}

      <div className="intro mb-3">
        <div className="container mb-12 mt-12 pb-12 pt-12 showcase">
          <div className="row justify-content-start">
            {/* <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1"> */}
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call showButton />
            </div>
            {/* {intro.frontmatter.image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img
                  alt={intro.frontmatter.title}
                  className={introImageClasses}
                  src={intro.frontmatter.image}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>

      <div className="index mt-10 mb-3">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: portfolioHeader }} />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{ __html: portfolioFirstParagraph }}
              />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-12 col-lg-6">
              <div
                dangerouslySetInnerHTML={{ __html: portfolioRest.join('') }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="row align-items-center"
                style={{ height: '100%' }}
              >
                <img
                  alt={portfolio.frontmatter.title}
                  src={portfolio.frontmatter.image}
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6 pb-6 strip-grey">
        <div className="container">
          <div className="col-12">
            <div className="row">
              <h2>Lösungshighlights</h2>
            </div>
            <div className="row">
              <ServicesOverview onlyFeatures />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6 pb-6">
        <div className="container">
          <PortfolioOverview />
        </div>
      </div>

      <div className="pt-6 pb-6 strip-grey">
        <div className="container">
          <div className="col-12">
            <div className="row">
              <h2>Unsere Kunden</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-12">
            <div className="row">
              <CustomerOverview />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6 pb-6">
        <div className="container">
          <JobPostingOverview />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    index: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        title
      }
      excerpt
    }
    intro: markdownRemark(
      fileAbsolutePath: { regex: "/content/index/intro.md/" }
    ) {
      html
      frontmatter {
        image
        image_absolute
        image_hide_on_mobile
      }
      excerpt
    }
    portfolio: markdownRemark(
      fileAbsolutePath: { regex: "/content/index/portfolio.md/" }
    ) {
      html
      frontmatter {
        image
        image_absolute
        image_hide_on_mobile
      }
      excerpt
    }
    portfolioEntries: allPortfolioJson {
      edges {
        node {
          id
          title
          icon
          items {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
