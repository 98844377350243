import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Card from './Card';

const ServicesOverview = ({ onlyFeatures = false }) => {
  const { serviceItemFiles } = useStaticQuery(graphql`
    query {
      serviceItemFiles: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/services/.*/" } }
        sort: { fields: [frontmatter___weight], order: ASC }
      ) {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              icon
              featured
            }
          }
        }
      }
    }
  `);

  const { edges: serviceItems } = serviceItemFiles;

  return (
    <div className="container">
      <div className="row">
        {serviceItems
          .filter(
            ({ node }) =>
              !onlyFeatures || (onlyFeatures && node.frontmatter.featured)
          )
          .map(({ node }) => (
            <div key={node.id} className="col-12 col-md-6 mb-1">
              <Card
                icon={node.frontmatter.icon}
                title={node.frontmatter.title}
                description={node.excerpt}
                link={node.fields.slug}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServicesOverview;
