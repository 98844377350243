import React from 'react';
import { Link } from 'gatsby';
import Icon from './Icon';

const Card = ({ icon, title, description, link }) => (
  <div className="card">
    <div className="card-icon-col">
      <Icon iconName={icon} className="card-icon" />
    </div>
    <div className="card-content-col">
      <h2>
        <Link to={link}>{title}</Link>
      </h2>
      <p>
        {description}
        {'. '}
        <Link to={link}>Mehr</Link>
      </p>
    </div>
  </div>
);

export default Card;
